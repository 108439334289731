/**
 * Copyright Highway9 Networks Inc.
 */
import { Tenant } from "~/types/tenant";
import APIService from "./APIServices";
import { authenticationService } from "./authentication-service";

const tenantAPI = new APIService<Tenant>("tenants");

export const tenantService = {
  getTenants: tenantAPI.getAll,
  createTenant: tenantAPI.create,
  deleteTenant: tenantAPI.delete,
  updateTenant: tenantAPI.update,
  retryTenant,
};

function retryTenant(id: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("Retrying tenant with id " + id);
  return fetch(`/api/v1/saas/tenants/${id}/retry`, requestOptions);
}
