import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { User } from "~/types/user";
import { authenticationService, userService } from "~/services";


type initState = {
    userInfo: User
    loading: boolean;
    current: User | null;
    data: Array<User>;
    open: boolean;
    changePassword: boolean;
}

const initialState: initState = {
    userInfo: {
        name: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
    },
    changePassword: false,
    loading: false,
    current: null,
    data: [],
    open: false,
}

export const fetchCurrentUserInfo = createAsyncThunk(
    `user/fetchCurrentUserInfo`,
    async () => {
        try {
            const currentUser = authenticationService.currentUserValue;
            const userInfo = await userService.getUserInfoByID(currentUser.userId);
            return userInfo
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);


export const fetchUsers = createAsyncThunk(`user/fetchUsers`, async () => {
    try {
        const data = await userService.getUserInfo();
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
});

// Create a slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.userInfo = action.payload;
        },
        setChangePassword: (state, action: PayloadAction<boolean>) => {
            state.changePassword = action.payload;
        },
        setValues: (state, action: PayloadAction<User|null>) => {
            state.current = action.payload;
        },
    },
    
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUserInfo.fulfilled, (state, action) => {
            state.current = action.payload;
            state.loading = false
            // userActions.setValues(state.current)
        });
        builder.addCase(fetchCurrentUserInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCurrentUserInfo.rejected, (state) => {
            state.loading = false;
        });
    }

});

// Export the action and reducer
export const userActions = userSlice.actions;
export default userSlice.reducer;


export const userState = (state: RootState) => state.user.current;
export const userData = (state: RootState) => state.user.data;
export const userOpen = (state: RootState) => state.user.open;
export const userLoading = (state: RootState) => state.user.loading;