import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ServiceInstance } from "~/types/serviceInstance"
import { text } from "~/components/TextResources";
import { deploymentCloudServices, serviceInstanceService } from "~/services";
import { Summary } from "~/types/summary";
import React from "react";
import { Icons } from "~/components/GraphicalResources";
import { DeploymentCloud } from "~/types/deploymentCLoud";

export type TenantTree = {
  id: string;
  name: string;
  vmcCount: number;
  vmcHealthyCount: number;
  icon?: React.ReactNode;
  children?: TenantTree[];
}

type initState = {
  open: boolean;
  loading: boolean;
  current: ServiceInstance | null;
  data: Array<ServiceInstance>;
  summary: Summary | null;
  treeData: TenantTree | null;
  profileOpen: boolean;
}
const initialState: initState = {
  loading: false,
  current: null,
  data: [],
  open: false,
  summary: null,
  treeData: null,
  profileOpen: false,
}
export const fetchServiceInstancesAndDeploymentCloud = createAsyncThunk(`serviceInstance/fetchServiceInstancesAndDeploymentCloud`, async () => {
  try {
    const [serviceInstancesData, deploymentCloudData] = await Promise.all([
      serviceInstanceService.getServiceInstances(),
      deploymentCloudServices.getClusterAgents()
    ]);

    return { serviceInstancesData, deploymentCloudData };
  } catch (error) {
    console.error(error);
    throw error;
  }
});
export const fetchServiceInstances = createAsyncThunk(`serviceInstance/fetchServiceInstance`, async () => {
  try {
    const data = await serviceInstanceService.getServiceInstances();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchDeploymentCloud = createAsyncThunk(`serviceInstance/fetchDeploymentCloud`, async () => {
  try {
    const data = await deploymentCloudServices.getClusterAgents();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchSaasSummary = createAsyncThunk(`serviceInstance/fetchSaasSummary`, async () => {
  try {
    const data = await serviceInstanceService.getSaasSummary();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const serviceInstanceSlice = createSlice({
  name: text.serviceInstance as string,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProfileOpen: (state, action: PayloadAction<boolean>) => {
      state.profileOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceInstancesAndDeploymentCloud.pending, (state) => {
        state.loading = true;
        // Sync current with latest data
      })
      .addCase(fetchServiceInstancesAndDeploymentCloud.fulfilled, (state, action) => {
        state.loading = false;
        
        // Extract data from the combined result
        const { serviceInstancesData, deploymentCloudData } = action.payload;

        state.data = serviceInstancesData;
        state.treeData = getTenentTree(serviceInstancesData);

        // Update the service instance with the latest deployment cloud data
        state.data = state.data.map((instance) => {
          const deploymentCloudItem = deploymentCloudData.find((cloud:DeploymentCloud) => cloud.id === instance.deploymentCloud.id);
          if (deploymentCloudItem) {
            instance.deploymentCloud = deploymentCloudItem;
          }
          return instance;
        });

        // Sync current with latest data
        if (state.current && !state.open) {
          const current = serviceInstancesData.find((item:any) => item.id === state.current?.id);
          if (current) {
            state.current = current;
          }
        }
      })
      .addCase(fetchSaasSummary.fulfilled, (state,action) => {
        state.summary = action.payload
        
        // Sync current with latest data
      })
  },
});

export const serviceInstanceActions = serviceInstanceSlice.actions;
export default serviceInstanceSlice.reducer;
export const serviceInstanceState = (state: RootState) => state.serviceInstance.current
export const serviceInstanceData = (state: RootState) => state.serviceInstance.data
export const serviceInstanceOpen = (state: RootState) => state.serviceInstance.open
export const serviceInstanceLoading = (state: RootState) => state.serviceInstance.loading;
export const tenantTree = (state: RootState) => state.serviceInstance.treeData;
export const saasSummary = (state: RootState) => state.serviceInstance.summary;
export const openProfileEdit = (state: RootState) => state.serviceInstance.profileOpen;


function getTenentTree(instances : ServiceInstance[]){
  const tenantTree: TenantTree = {
    id : "all",
    name : "All",
    vmcCount : 0,
    vmcHealthyCount : 0,
    children : []
  }
  const list = tenantTree.children;

  instances.forEach((instance) => {
    const tenant = list?.find((item) => item.id === instance.tenantId);
    if (tenant) {
      tenant.vmcCount += 1;
      tenant.vmcHealthyCount += instance.runtimeInfo.monitorStatus?.health === "GOOD" ? 1 : 0;
    } else {
      list?.push({
        id: instance.tenantId,
        name: instance.tenantName,
        vmcCount: 1,
        vmcHealthyCount: instance.runtimeInfo.monitorStatus?.health === "GOOD" ? 1 : 0,
        icon: Icons.tenant,
      });
    }
  });
  return tenantTree;
}



