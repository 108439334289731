import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs : 300,
      sm : 600,
      md : 900,
      lg : 1500,
      xl : 2000
    }
  },
  palette: {
    primary: {
      main: "#026951",
    },
    secondary: {
      main: "#009688",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: "Inter, sans-serif",
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 100,
    },
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
        variant: "contained",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 32,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: "small",
      },
    },
  },
});
