/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, useMemo } from "react";
import {
  Theme,
  SxProps,
} from "@mui/material";
import { Icons } from "../components/GraphicalResources";
import { text } from "../components/TextResources";
import { authenticationService, userService } from "../services";
import EditProfile from "./userprofile/EditProfie";
import { TopBar as TopBarComponent, ProfileCard } from '@h9/ui-lib'
import { API_DOCS, LICENSE_AGREEMENT } from "~/constants/routes";
import { roleStringFormatter } from "~/helpers/utils";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { openProfileEdit, serviceInstanceActions } from "~/store/context/serviceInstanceSlice";
import {  fetchCurrentUserInfo,userState, } from "~/store/context/userSlice";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const TenantName = useMemo(() => {
    let name = '';
    if (!authenticationService.isOperator()) {
      name = authenticationService.currentUserValue?.tenantName ?? '';
    }
    return name;
  }, []);


  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isEditOpen = useAppSelector(openProfileEdit);
  const user = useAppSelector(userState)
  
  useEffect(() => {
    dispatch(fetchCurrentUserInfo());
  }, [dispatch]);
  
  

  const setIsEditOpen = (open: boolean) => {
    dispatch(serviceInstanceActions.setProfileOpen(open));
  };

  const handleEditProfileClose = (type:string) => {
    setIsEditOpen(false);
    if (type === "refresh") {
      setTimeout(async () => {
        await dispatch(fetchCurrentUserInfo());
      }, 1 * 1000);
    }
  };

  const userRole = userService?.currentUserInfo?.roles?.map(roleStringFormatter).join(",") ?? "";

  const handleEditProfileOpen = () => {
    setAnchorEl(null);
    setIsEditOpen(true);
  };

 
  return (
    <TopBarComponent
      title={`${text.navBarTitle}`}
      subtitle={TenantName}
      userName={user?.name}
      role={userRole}
      setAnchorEl={setAnchorEl}
      profileCard={
        <ProfileCard
          profile={{
            name: user?.name ?? '',
            role: user?.roles?.[0] ?? '',
            email: user?.email ?? ''
          }}
          menuOptions={[
            {
              icon: Icons.edit,
              text: text.editProfile!,
              onClick: handleEditProfileOpen
            },
            {
              icon: Icons.code,
              text: text.apiDocs!,
              onClick: () => {
                window.open(API_DOCS, "_blank")
              }
            },
            {
              icon: Icons.documentIcon,
              text: text.EULAText!,
              onClick: () => {
                window.open(LICENSE_AGREEMENT, "_self")
              }
            },
            {
              icon: Icons.logout,
              text: text.logout!,
              style: commonStyles.logoutItem,
              onClick: () => {
                authenticationService.logout();
              }
            }
          ]}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      }
    >
      {isEditOpen && <EditProfile state={isEditOpen} handleClose={handleEditProfileClose} />}

    </TopBarComponent>
  )
};

export default TopBar;

const commonStyles = {
  logoutItem: {
    color: "#D32F2F !important",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px",
      color: "#DF3D2D",
    },
  },
} satisfies Record<string, SxProps<Theme>>;