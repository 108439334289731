// @Copyight 2023 - present Highway 9 Networks

import { RequestOptions } from "~/types/requestOptions";
import { authenticationService } from "./authentication-service";

const baseURL = "/api/v1/saas/";

// type Read<T> = {[P in keyof T]: T[P]}

class APIService<T> {
  readonly url: string = baseURL;

  constructor(objectType: string) {
    this.url = baseURL + objectType;

    // function binding
    this.getAll = this.getAll.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.upload = this.upload.bind(this);
  }

  async getAll() {
    const options: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    try {
      const response = await fetch(this.url, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async create(data: T) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(this.url, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async update(data: Partial<T>) {
    const options: RequestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: JSON.stringify(data),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${this.url}/${data.id}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const _data = result?.data ?? result;
      return _data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async delete(id: string) {
    const options: RequestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    try {
      const response = await fetch(`${this.url}/${id}`, options);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

 



  async get(path: string) {
    const options: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async post<U = Response>(path: string, data?: U) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    if (data) {
      options.body = JSON.stringify(data);
    }
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      if (data) {
        const text = await response.text();
        const result = text && JSON.parse(text);
        const data = result?.data ?? result;
        return data as T;
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async upload(path: string, file: File, metaData?: any) {
    const formData = new FormData();
    formData.append("file", file);
    if (metaData) {
      formData.append("metaData", JSON.stringify(metaData));
    }
    const options: RequestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: formData,
    };
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default APIService;
