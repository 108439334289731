/**
 * Copyright Highway9 Networks Inc.
 */
import { userService } from "~/services";
import { store } from "~/store";
import { utilityActions } from "~/store/context/utilitySlice";

function onAppStart(){
    console.log("App started");

    // update timezone
    updateTimezone();

} 

export default onAppStart;

async function updateTimezone() {
    const userInfo = await userService.getUserInfo();
    // sync user to store
    store.dispatch(utilityActions.setUser(userInfo));
    
    if (userInfo.timezone) {
        store.dispatch(utilityActions.setTimeZone(userInfo.timezone));
        return;
    }
}