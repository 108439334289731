/**
 * Copyright Highway9 Networks Inc.
 **/

import { useState } from "react";
import { authenticationService } from "../services";
import { text } from "../components/TextResources";
import useErrorsAndWarnings from "../hooks/useErrorsAndWarnings";
import ErrorsComponent from "../components/ErrorsComponent";
import { useLocation, useHistory } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";

import { ResetView } from '@h9/ui-lib';

export function ResetPasswordPage() {
  const location = useLocation();
  const history = useHistory();

  const search = location.search;
  const urlParams = new URLSearchParams(search);
  const email = urlParams.get("user") || "";
  const OTP = urlParams.get("otp") || "";

  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } =
    useErrorsAndWarnings();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  console.log("errors", errors);

  async function handleSubmit(password: string, confirmPassword: string) {
    setLoading(true);
    try {
      if (password.length < 4) {
        throw { errors: [{ message: text.passwordTooShort }], warnings: [] };
      }
      if (password !== confirmPassword) {
        throw { errors: [{ message: text.passwordNotMatch }], warnings: [] };
      }

      console.log("Reset Password", email, password, OTP);

      const user = await authenticationService.resetPassword(email, password, OTP);
      console.log("Password Reset Done", user);
      setOpen(true);
    } catch (error : any) {
      setErrorsAndWarnings(error.errors, error.warnings);
    } finally {
      setLoading(false);
    }
  }

  const onConfirm = () => {
    setOpen(false);
    history.replace("/");
  };

  return (
    <>
      <ResetView
        email={email!}
        errorsComponent={
          <ErrorsComponent
            errorsData={errors}
            warningsData={warnings}
            closeAlert={closeAlert}
            closeAll={closeAll} />
        }
        loading={loading}
        onReset={handleSubmit}
      />

      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Password Reset"
        onConfirm={onConfirm}
        message="Your password has been reset successfully. 
                You will be redirected to the login page."
      />
    </>
  );
}
