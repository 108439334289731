/**
 * Copyright Highway9 Networks Inc.
 */
import { Partner } from "~/types/partner";
import APIService from "./APIServices";

const partnerAPI = new APIService<Partner>("partners");

export const partnerService = {
  getPartners: partnerAPI.getAll,
  createPartner: partnerAPI.create,
  deletePartner: partnerAPI.delete,
  updatePartner: partnerAPI.update,
};



