import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hardwareService } from "~/services";
import { Product } from "~/types/Product";
import { RootState } from "..";

type initState = { 
    current: Product | null;
    data: Array<Product>;
    open: boolean;
    isEdit: boolean;
 }

const initialState: initState = {
    current: null,
    data: [],
    open: false,
    isEdit: false,
}

export const fetchProducts = createAsyncThunk(`product/fetchProducts`, async () => {
    try {
        const data = await hardwareService.getProducts();
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
});

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setCurrentProduct: (state, action: PayloadAction<Product>) => {
            state.current = action.payload;
        },
        setProducts: (state, action: PayloadAction<Array<Product>>) => {
            state.data = action.payload;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    }
});

export const productActions = productSlice.actions;
export default productSlice.reducer;

export const productData = (state : RootState) => state.product.data;
export const productCurrent = (state : RootState) => state.product.current;
export const productOpen = (state : RootState) => state.product.open;