/**
 * Copyright Highway9 Networks Inc.
 */

import { ServiceInstance } from "~/types/serviceInstance";
import APIService from "./APIServices";
import { authenticationService } from './authentication-service';
import { Summary } from "~/types/summary";
const serviceInstanceAPI = new APIService<ServiceInstance>("serviceInstances");
export const serviceInstanceService = {
    getServiceInstances:serviceInstanceAPI.getAll,
    deleteServiceInstance,
    upgradeServiceInstance,
    retryServiceInstance,
    updateServiceInstance,
    getSaasSummary,
    getReleases,
    updateRelease,
    deleteRelease,
    getMetrics,
    getVMCReleases
};


function updateServiceInstance(id:string, body:any) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(body)
    };

    return fetch(`api/v1/saas/serviceInstances/${id}`, requestOptions);
}

function upgradeServiceInstance(id:string, body:any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(body)
    };
    

    console.log("Upgrading service instance with id " + id);
    return fetch(`/api/v1/saas/serviceInstances/${id}/operations/upgrade`, requestOptions);
}

function deleteServiceInstance(id:string, isForced:boolean) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },

    };

    console.log("Deleting service instance with id " + id);
    return fetch(`/api/v1/saas/serviceInstances/${id}?isForceDelete=${isForced}`, requestOptions);
}

function retryServiceInstance(id:string) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },

    };

    console.log("Retrying service instance with id " + id);
    return fetch(`/api/v1/saas/serviceInstances/${id}/operations/retry`, requestOptions);
}

async function getSaasSummary() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
    };

    const res = await fetch(`/api/v1/saas/summary`, requestOptions);
    const result = await res.json();
    return result as Summary;

}

function updateRelease(id:string, body:any) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(body)
    };

    return fetch(`api/v1/saas/serviceInstances/releases/` + id, requestOptions);
}

function getReleases() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
    };
    return fetch(`api/v1/saas/serviceInstances/releases`, requestOptions);
}

function getVMCReleases(payload:any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(payload)
    };

    return fetch(`api/v1/saas/serviceInstances/releases/query`, requestOptions);
}


function deleteRelease(id:string) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        }

    };

    return fetch(`api/v1/saas/serviceInstances/releases/` + id, requestOptions);
}
// TODO
async function getMetrics(query:any) {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(query),
    };
    try {
        const response = await fetch(`api/v1/saas/edges/metrics/aggregate`, options);
        const text = await response.text();
        const result = text && JSON.parse(text);
        const data = result?.data ?? result;
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
