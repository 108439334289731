/**
 * Copyright Highway9 Networks Inc.
 */
import { DeploymentCloud } from '~/types/deploymentCLoud';
import { authenticationService } from '.';
import { config } from '../config';

async function getClusterAgents() {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
        };
        const response = await fetch(`${config.apiUrl}/deploymentClouds`, requestOptions);
        const text = await response.text();
        const data = text && JSON.parse(text).data;
        return data as DeploymentCloud[];
    } catch (error) {
        console.log(error)
        throw error
    }
}
const updateDeploymentCloud = async (requestBody : any) => {
    try {
        console.log(requestBody)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
            body: JSON.stringify(requestBody)
        };
       return fetch(`${config.apiUrl}/deploymentClouds/${requestBody.id}`, requestOptions);
       
    } catch (error) {
        console.log(error)
        throw error
    }
}

const deleteDeploymentCloud = async (id : string) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
        };
        return fetch(`${config.apiUrl}/deploymentClouds/${id}`, requestOptions);
       
    
    } catch (error) {
        console.log(error)
        throw error
    }
}

const setDefaultDeploymentCloud = async (data : any) => {
    try {
        console.log(data)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
            body: JSON.stringify(data)
        };
        return fetch(`${config.apiUrl}/deploymentClouds/${data.id}`, requestOptions);
       
    
    } catch (error) {
        console.log(error)
        throw error
    }
}
const approveDeploymentCloud = async (data : any) => {
    try {
        console.log(data)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
            body: JSON.stringify(data)
        };
        return fetch(`${config.apiUrl}/deploymentClouds/${data.id}`, requestOptions);
       
    
    } catch (error) {
        console.log(error)
        throw error
    }
}
const getDeploymentCloud = async (payload :any) => {
    try {
        console.log(payload)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
            body: JSON.stringify(payload)
        };
        const response = await fetch(`${config.apiUrl}/system/settings/query`, requestOptions);
        const text = await response.text();
        const data = text && JSON.parse(text).data;
        return data
    } catch (error) {
        console.log(error)
        throw error
    }
}
const updateDeploymentCloudStatus = async (payload : any) => {
    try {
        console.log(payload)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
            },
            body: JSON.stringify(payload)
        };
        const response = await fetch(`${config.apiUrl}/system/settings/${payload.id}`, requestOptions);
        const text = await response.text();
        const data = text && JSON.parse(text).data;
        return data
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const deploymentCloudServices = {
    getClusterAgents,
    approveDeploymentCloud,
    deleteDeploymentCloud,
    setDefaultDeploymentCloud,
    updateDeploymentCloud,
    getDeploymentCloud,
    updateDeploymentCloudStatus
}