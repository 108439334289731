/**
 * Copyright Highway9 Networks Inc.
 */
import { encrypt } from '~/helpers/utils';
import { handleResponse } from '../helpers';


export const authenticationService = {
    login,
    logout,
    resetPassword,
    forgetPassword,
    isOperator,
    postSession,
    getSessionInfo,
    createServiceInstanceSession,
    isTenant,
    isPartner,
    updateCurrentValue,
    get currentUserValue() { return getCurrentUser() }
};

function getCurrentUser() {
    if (typeof window !== "undefined") {
        const state = localStorage.getItem("currentUser");
        if (state) {
            return JSON.parse(state);
        } else {
            return null;
        }
    }
    return null;
}

async function login(username: string, password: string, publicKey: string) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({ "email": username, "password": encrypt(password, publicKey) })
    };

    try {
        const response = await fetch(`/api/v1/saas/sessions/login`, requestOptions);
        const res = await response.text();
        const data = res && JSON.parse(res);
        if (!response.ok) {
            if ([401].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(data));
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}



async function resetPassword(username: string, password: string, otp: string) {
    const sessionInfo = await authenticationService.getSessionInfo(username.trim());

    if (!sessionInfo?.authKey) {
        throw new Error("Invalid user");
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({
            "name": "user-reset-password",
            "type": "operation",
            "operationType": "RESET_PASSWORD",
            "objectType": "user",
            "input": [
                { "key": "userName", "value": username },
                { "key": "otp", "value": otp },
                { "key": "newPassword", "value": encrypt(password, sessionInfo.authKey) }
            ],
        })
    };

    return fetch(`/api/v1/saas/operations/resetPassword`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
            throw error
        });
}
function forgetPassword(username: string) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({
            name: "user-forgot-password",
            type: "operation",
            operationType: "FORGOT_PASSWORD",
            objectType: "user",
            input: [
                { key: "userName", value: username }
            ]
        })
    };
    return fetch(`/api/v1/saas/operations/forgotPassword`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
            throw error
        });
}
async function getSessionInfo(email: string, redirectUrl = "/") {
    const res = await fetch(`/api/v1/saas/sessions/login/query?uri=${redirectUrl}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
        },
        redirect: "manual",
        body: JSON.stringify({ email })
    });

    if (!res.ok) {
        throw new Error("Invalid user");
    }

    const data = await res.json();
    if (data.authType === "PASSWORD") {
        return {
            authType: "PASSWORD",
            authKey: data.authKey
        }
    } else if (data.authType === "SAML") {
        return {
            authType: "SAML",
            redirectUrl: data.redirectUrl
        }
    }
}

async function postSession() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    };

    //return fetch(`${config.apiUrl}/api/v1/saas/sessions/login`, requestOptions)
    return fetch(`/api/v1/saas/sessions/session`, requestOptions)
        .then(response => {
            return response.text().then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', user);
                return JSON.parse(user);
            })
        }).catch(error => {
            console.log(error)
            throw error
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem("userInfo")
    localStorage.clear();
    window.location.href = '/login';
}

function isOperator() {
    return Boolean(authenticationService?.currentUserValue?.isOperator)
}
function updateCurrentValue(updatedValue: any) {
    const currentUser = authenticationService.currentUserValue;
    const updatedCurrentUser = {
        ...currentUser,
        ...updatedValue
    };
    localStorage.setItem("currentUser", JSON.stringify(updatedCurrentUser));
    return updatedCurrentUser;
}

function isPartner() {
    return authenticationService?.currentUserValue?.userType === "partner"
}

function isTenant() {
    return authenticationService?.currentUserValue?.userType === "tenant"
}

function createServiceInstanceSession(id: string) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + authenticationService.currentUserValue.token,
        },
    };

    return fetch(`/api/v1/saas/sessions/serviceInstances/${id}`, requestOptions);
}