// @Copyright 2023 - present Highway9 Networks
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { loadingActions } from "~/store/context/loadingSlice";


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

type Props = {
  action?: React.ReactNode;
};

const SnackbarX = (props: Props) => {

  const { open, message, severity} = useAppSelector((state) => state.loading.snackBar);

  const dispatch = useAppDispatch();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(loadingActions.setSnackBar({ open: false, message: "", severity: "success" }));
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} action={props.action}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarX;
