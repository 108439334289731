import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Tenant } from "~/types/tenant";
import { authenticationService, tenantService } from "~/services";

import { ServiceInstance, EdgeSummary, DeviceSummary } from "~/types/serviceInstance";
import { groupBy } from "~/helpers/utils";
export interface TenantX extends Tenant {
  deviceSummary: DeviceSummary;
  edgeSummary: EdgeSummary;
  vmcCount: number;
  countVMC: Record<string, number>;
}

type initState = {
  open: boolean;
  loading: boolean;
  current: Tenant | null;
  data: Array<Tenant>;
};
const initialState: initState = {
  loading: true,
  current: null,
  data: [],
  open: false,
};
export const fetchTenants = createAsyncThunk(`tenant/fetchTenants`, async () => {
  try {
    const data = await tenantService.getTenants();
    const EULAAccepted = data[0]?.eulaAccepted || false;
    const updatedCurrentUser = {
      isEULAAccepted: EULAAccepted,
    };
    authenticationService.updateCurrentValue(updatedCurrentUser);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenants.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
      state.loading = false;
    })
      .addCase(fetchTenants.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const UpdatedTenant = (Tenants: Tenant[], ServiceInstances: ServiceInstance[]) => {
  const groupedServiceInstanceData = groupBy(ServiceInstances, "tenantName");

  return Tenants.map((item) => {
    const data: TenantX = JSON.parse(JSON.stringify(item));
    const groupByLocation = groupedServiceInstanceData[item.name]
      ? groupBy(groupedServiceInstanceData[item.name], "location.locationType")
      : {};

    data.countVMC = Object.fromEntries(
      Object.entries(groupByLocation).map(([key, value]) => [key, value.length])
    );

    data.vmcCount = Object.values(groupByLocation).reduce((count, instances) => count + instances.length, 0);

    const serviceInstance = ServiceInstances.find((t) => t.tenantId === item.id);
    data.edgeSummary = serviceInstance?.edgeSummary as EdgeSummary;
    data.deviceSummary = serviceInstance?.deviceSummary as DeviceSummary;

    return data;
  });
};

export const tenantActions = tenantSlice.actions;
export default tenantSlice.reducer;
export const tenantState = (state: RootState) => state.tenant.current;
export const tenantData = (state: RootState) => state.tenant.data;
export const tenantOpen = (state: RootState) => state.tenant.open;
export const tenantLoading = (state: RootState) => state.tenant.loading;
