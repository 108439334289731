/**
 * Copyright Highway9 Networks Inc.
 */
import React from "react";
import { Images } from "~/components/GraphicalResources"
import Typography from "@mui/material/Typography";
import Button  from "@mui/material/Button"

export default function NotImplemented() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <img src={Images.wentWrong} alt="error" />
      <Typography variant="h4">The page you are looking for is not available.</Typography>
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Go Back to Dashboard
      </Button>
    </div>
  );
}
