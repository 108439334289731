/**
 * Copyright Highway9 Networks Inc.
 */
import { Role } from '~/types/Roles';
import { authenticationService } from '.';
import { User } from '~/types/user';
import APIService from './APIServices';

const tenantUserAPI = new APIService<User>('tenants');
const userAPI = new APIService<User>('users');

export const userService = {
    getUsers,
    getAuditLogs,
    deleteUser,
    addUser,
    updateUser,
    getUserRoles,
    updateUserProfile,
    getUserInfoByID,
    getUserInfo,
    getUserResetPasswordLink,
    get currentUserInfo() {
        return getUserInfoLocalStorage();
    },
};


async function getUsers(tenantID?: string) {
    if (!tenantID) {
        return userAPI.getAll();
    }
    return tenantUserAPI.get(`${tenantID}/users`) as unknown as User[];
}
async function getUserInfoByID(id: string) {
    return userAPI.get(id)
}

async function getAuditLogs(filters: any) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(filters),
    };
    const logs = await fetch('/api/v1/saas/auditLogs/query', requestOptions).then(res => res.json())
    console.log(logs)
    return logs
}

async function getUserRoles() {
    return userAPI.get('roles') as unknown as Role[];
}

async function addUser(requestBody: any) {
    return userAPI.post('', requestBody);
}
async function updateUserProfile(requestBody: any) {
    try {
        await userAPI.post('profile', requestBody);
        const currentUser = authenticationService.currentUserValue;
        const userInfo = await userService.getUserInfoByID(currentUser.userId);
        // Store the updated user info in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        return userInfo;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
}
 
async function updateUser(requestBody: any, id: string) {
    const data = {
        ...requestBody,
        id
    }
    return userAPI.update(data);
}

async function deleteUser(id: string) {
    return userAPI.delete(id);
}

async function getUserInfo() {
    if (typeof window !== "undefined") {
        const state = localStorage.getItem("userInfo");
        if (state) {
            return JSON.parse(state) as User;
        }
    }
    const currentUser = authenticationService.currentUserValue;
    const userInfo = await userService.getUserInfoByID(currentUser.userId);
    console.log(userInfo);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return userInfo;
}

async function getUserResetPasswordLink(email: string) {
    const operation = {
        "name": "user-forgot-password",
        "type": "operation",
        "operationType": "FORGOT_PASSWORD",
        "objectType": "user",
        "input": [
            {
                "key": "userName",
                "value": email
            }
        ]
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + authenticationService.currentUserValue.token,
        },
        body: JSON.stringify(operation),
    };

    const response = await fetch('/api/v1/saas/operations/forgotPassword', requestOptions).then(res => res.json());

    return response as {
        input : null,
        output : {
            key : string,
            value : string
        }[],
     };
}

function getUserInfoLocalStorage() {
    if (typeof window !== "undefined") {
      const state = localStorage.getItem("userInfo");
      if (state) {
        return JSON.parse(state) as User
      } else {
        return null;
      }
    }
    return null;
  }