import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Partner } from "~/types/partner";
import  {authenticationService, partnerService} from "~/services";


type initState = {
    open: boolean;
    loading: boolean;
    current: Partner | null;
    data: Array<Partner>;
}
const initialState: initState = {
    loading: false,
    current: null,
    data: [],
    open: false,
}
export const fetchPartners = createAsyncThunk(`partner/fetchPartners`, async () => {
    try {
        const data = await partnerService.getPartners();
        const EULAAccepted = data[0]?.eulaAccepted || false;
        const updatedCurrentUser = {
        isEULAAccepted: EULAAccepted,
        };
        authenticationService.updateCurrentValue(updatedCurrentUser);
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
});

const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPartners.fulfilled, (state, action) => {
            state.data = action.payload;
            // Sync current with latest data
            if (state.current && !state.open) {
                const current = action.payload.find((item) => item.id === state.current?.id);
                if (current) {
                    state.current = current;
                }
            }
        });
    },
});


export const partnerActions = partnerSlice.actions;
export default partnerSlice.reducer;
export const partnerState = (state: RootState) => state.partner.current
export const partnerData = (state: RootState) => state.partner.data
export const partnerOpen = (state: RootState) => state.partner.open
export const partnerLoading = (state: RootState) => state.partner.loading;

