/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
} from "@mui/material";

import { authenticationService } from "../services";
import ErrorsComponent from "../components/ErrorsComponent";
import useErrorsAndWarnings from "../hooks/useErrorsAndWarnings";
import ForgotPassword from "./ForgotPassword";
import { useAppDispatch } from "~/store/hooks";
import { fetchTenants } from "~/store/context/tenantSlice";
import { useHistory } from "react-router-dom";
import { LoginView } from "@h9/ui-lib";


export function LoginPage() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [loading, setLoading] = useState(true);
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [openForget, setOpenForget] = useState(false);
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleSubmit = async (email: string, password: string) => {
    
    setLoadingButton(true);
    try {
      const sessionInfo = await authenticationService.getSessionInfo(email.trim());
      if (!sessionInfo) {
        return;
      }

      await authenticationService.login(email.trim(), password.trim(), sessionInfo.authKey);
      console.log("Logged in.");

      if (authenticationService.isTenant()) {
        await dispatch(fetchTenants());
      }
      const url = sessionStorage.getItem("url")?.replace(/^.*\/\/[^\\/]+/, '') || "/";
      history.replace(url);
    } catch (error : any) {
      console.error(error);
      setErrorsAndWarnings(error.errors, error.warnings);
    } finally {
      setLoadingButton(false);
    }
  };


  const handleNext = async (email: string) => {
    if (!email) return;

    setLoadingButton(true);
    try {
      const json = await authenticationService.getSessionInfo(email);
      if (json) {
        if (json.authType === "PASSWORD") {
          console.log("PASSWORD AUTH");
        } else if (json.authType === "SAML") {
          console.log("SAML AUTH");
          window.location.href = json.redirectUrl;
        }
      }
    } catch (err) {
      console.log("Unknown user", err);
    } finally {
      setHidePassword(false);
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    authenticationService
      .postSession()
      .then((user) => {
        if (user.userId) {
          // getUserInfo(user.userId, user.token);
          const url = sessionStorage.getItem("url") || "/";
          window.location.replace(url);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorsAndWarnings(error.errors, error.warnings);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div
      style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress size={80} />
    </div>
  }

  return (
    <>
      <LoginView
        errorsComponent={
          <ErrorsComponent
            errorsData={errors}
            warningsData={warnings}
            closeAll={closeAll}
            closeAlert={closeAlert}
          />
        }
        loading={loadingButton}
        hidePassword={hidePassword}
        onLogin={handleSubmit}
        onNext={handleNext}
        onForgotPassword={() => { setOpenForget(true) }}
      />
      <ForgotPassword open={openForget} setOpen={setOpenForget} />

    </>
  )

}
