/**
 * Copyright Highway9 Networks Inc.
 */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { DeviceInfo } from "~/types/deviceInfo";
import { deviceInfoService } from "~/services";

type initState = {
  open: boolean;
  loading: boolean;
  current: DeviceInfo | undefined;
  data: Array<DeviceInfo>;
};

const initialState: initState = {
  open: false,
  loading: true,
  current: undefined,
  data: [],
};

export const fetchDeviceInfos = createAsyncThunk(`devices/fetchDeviceInfos`, async () => {
  try {
    const data = await deviceInfoService.getSubscribersDeviceInfo();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const deviceManagementSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setDeviceInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedDeviceInfoRow: (state, action: PayloadAction<DeviceInfo | undefined>) => {
      state.current = action.payload;
    },
    setDeviceInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceInfos.fulfilled, (state, action) => {
      state.data = action.payload;
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    });
  },
});

export const deviceActions = deviceManagementSlice.actions;
export default deviceManagementSlice.reducer;

export const deviceInfoOpen = (state: RootState) => state.devices.open;
export const selectedDeviceInfo = (state: RootState) => state.devices.current;
export const deviceInfoLoading = (state: RootState) => state.devices.loading;
export const deviceInfoData = (state: RootState) => state.devices.data;
