import { DeviceInfo } from "~/types/deviceInfo";
import APIService from "./APIServices";

const deviceInfoAPI = new APIService<DeviceInfo>("deviceInfo");

export const deviceInfoService = {
  getSubscribersDeviceInfo: deviceInfoAPI.getAll,
  getTacSubscribersDeviceInfo,
  postSubscribersDeviceInfo: deviceInfoAPI.create,
  putSubscribersDeviceInfo: deviceInfoAPI.update,
  deleteSubscribersDeviceInfo: deviceInfoAPI.delete,
};

async function getTacSubscribersDeviceInfo(tac: string) {
  try {
    const url = `tac/${tac}`;
    return await deviceInfoAPI.get(url);
  } catch (error) {
    console.log(error);
    throw error;
  }
}
