/**
 * Copyright Highway9 Networks Inc.
 */

import { authenticationService } from "~/services";
import { createCache, fetchCache } from "./cache";
import { getFakeData } from "./fake-backend";

const ENV_PROXY = import.meta.env.H9_API_PROXY_URL;
const isDev = window.location.host.includes("localhost");
//const excludeEndpointsFromRealFetch = ["api/v1/saas/edges/metrics"]; //Add the endpoint which you want to get the data from local fake backend.

export const realFetch = window.fetch;
export function configureFetch() {
  window.fetch = function (url, opts) {
    const src = url.toString();
    const options = opts || {};

    return new Promise(async (resolve, reject) => {
      //if not local, then make real calls.
      //if (!excludeEndpointsFromRealFetch.includes(url as string)) {
      if (ENV_PROXY || !isDev) {
        const isCached = await fetchCache(src, options, resolve, reject);
        if (isCached) {
          return;
        }

        return realFetch(url, opts)
          .then((response) => {
            if (!response.ok) {
              throw response;
            }
            // if response is ok and method is GET then add it in cache object
            return createCache(src, options, response, resolve, reject);
          })
          .catch((err) => {
            try {
              if (typeof err.json === "function") {
                err
                  .json()
                  .then((body: any) => {
                    if (err.status === 401 && body.errors[0].message === "Token is expired") authenticationService.logout();
                    return reject(body);
                  })
                  .catch(() => {
                    return reject(err);
                  });
              }
            } catch (error) {
              // return the original err not error
              return reject(err);
            }
          });
      }
      //}

      getFakeData(src, options, resolve, reject);
    });
  };
}
