import { jsx as e, Fragment as l, jsxs as n } from "react/jsx-runtime";
import { useState as d } from "react";
import { Grid as t, CircularProgress as y } from "@mui/material";
import { Button as C } from "../atoms/Button/Button.js";
import "../../map-85279df3.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import { Input as c } from "../atoms/Input/Input.js";
import { Typography as i } from "../atoms/Typography/Typography.js";
import { asset as x } from "../../assets/icons/index.js";
import { d as F } from "../../ArrowForward-8f6374ed.js";
const z = ({ errorsComponent: s, loading: a, email: g, onReset: u }) => {
  const [p, h] = d(""), [m, f] = d("");
  function w(r) {
    r == null || r.preventDefault(), u(p, m);
  }
  return /* @__PURE__ */ e(l, { children: /* @__PURE__ */ e(t, { container: !0, sx: o.main, children: /* @__PURE__ */ n(t, { sx: o.loginContainer, children: [
    /* @__PURE__ */ n(t, { sx: o.topContainer, children: [
      /* @__PURE__ */ e(t, { sx: o.logo, children: /* @__PURE__ */ e("img", { src: x.loginPageLogo, width: "236" }) }),
      /* @__PURE__ */ n(t, { component: "form", onSubmit: w, sx: o.loginForm, children: [
        /* @__PURE__ */ e(i, { sx: o.ResetTitleText, color: "#141414", children: "Reset your password" }),
        /* @__PURE__ */ n(i, { children: [
          "Hi, ",
          g
        ] }),
        /* @__PURE__ */ e(i, { sx: o.infoText, color: "#878787", children: "Please enter your new password." }),
        /* @__PURE__ */ e(t, { sx: o.error, children: s }),
        /* @__PURE__ */ e(
          c,
          {
            label: "Password",
            id: "password",
            name: "password",
            required: !0,
            type: "password",
            placeholder: "Password",
            sx: o.inputPass,
            value: p,
            onChange: (r) => h(r.target.value)
          }
        ),
        /* @__PURE__ */ e(
          c,
          {
            label: "Confirm Password",
            id: "confirmPassword",
            name: "confirmPassword",
            required: !0,
            type: "password",
            placeholder: "Confirm Password",
            sx: o.inputPass,
            value: m,
            onChange: (r) => f(r.target.value)
          }
        ),
        /* @__PURE__ */ e(C, { type: "submit", sx: o.loginButton, disabled: a, children: a ? /* @__PURE__ */ n(l, { children: [
          a && /* @__PURE__ */ e(y, { size: 24 }),
          "  "
        ] }) : /* @__PURE__ */ n(l, { children: [
          /* @__PURE__ */ e(i, { type: "bodyText1", color: "#FFFFFF", sx: o.resetText, children: "Change Password" }),
          /* @__PURE__ */ e(F, {})
        ] }) }),
        /* @__PURE__ */ e("div", { style: o.actions })
      ] })
    ] }),
    /* @__PURE__ */ e(t, { sx: o.noAccount, children: /* @__PURE__ */ e("div", { onClick: () => {
      window.open("mailto:support@highway9.com");
    }, children: /* @__PURE__ */ e(i, { type: "heading3", color: (r) => r.palette.primary.main, sx: o.pointer, children: "Contact Support" }) }) })
  ] }) }) });
}, o = {
  main: (s) => ({
    width: "100vw",
    height: "100vh",
    overflow: "none",
    backgroundColor: s.palette.primary.main,
    backgroundImage: `url(${x.loginPageBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }),
  logo: {
    textAlign: "center",
    marginBottom: "40px"
  },
  loginContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "100%",
    mx: "8px",
    maxHeight: "95vh",
    maxWidth: "500px"
  },
  topContainer: {
    padding: "3rem 3.5rem 2rem"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px"
  },
  noAccount: {
    borderTop: "1px solid #EFEFEF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "32px",
    gap: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  loginButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    marginBottom: "16px",
    "&::before ": {
      content: "''",
      width: 0
    }
  },
  infoText: {
    fontSize: ".8rem",
    marginTop: "8px"
  },
  ResetTitleText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "8px"
  },
  resetText: {
    textAlign: "center"
  },
  inputEmail: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  inputPass: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  error: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
    width: "100%",
    "& .MuiAlert-root": {
      borderRadius: "8px"
    }
  }
};
export {
  z as default
};
