import React, { useEffect, useState } from "react";
import { text } from "../../components/TextResources";
import ErrorsComponent from "../../components/ErrorsComponent";
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography, Button } from "@mui/material";
import useErrorsAndWarnings from "../../hooks/useErrorsAndWarnings";
import { authenticationService, userService } from "../../services";
import { DialogActions, DialogBox, DialogContent, DialogTitle } from "~/styles/Dialog";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import timezones from "~/assets/timezones.json";
import { SearchSelector } from "@h9/ui-lib";
import moment from "moment-timezone";
import { selectTimeZone, utilityActions } from "~/store/context/utilitySlice";
import { encrypt } from "~/helpers/utils";
import { useLoading, useSnackBar } from "~/store/context/loadingSlice";
import { User } from "~/types/user";
import { fetchCurrentUserInfo, userActions, userState } from "~/store/context/userSlice";

interface EditProfileProps {
  state: boolean;
  handleClose: (type: string) => void;
}


const EditProfile = (props: EditProfileProps) => {
  const dispatch = useAppDispatch();
  const tz = useAppSelector(selectTimeZone);


  const user = useAppSelector((state) => state.user.userInfo)
  const _user = useAppSelector(userState)

  const [isValid, setIsValid] = useState(true);

  const [changePassword, setChangePassword] = useState(false);
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();

  const { setSnackBar } = useSnackBar();

  const { setLoading, LoadingComponent } = useLoading();

  useEffect(() => {
    if (props.state) dispatch(fetchCurrentUserInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state]);

  useEffect(() => {
    dispatch(
      userActions.setUser({
        ..._user,
        name: _user?.name as string,
        email: _user?.email as string,
        timezone: _user?.timezone,
      })
    )

  }, [dispatch, props.state, _user])

  useEffect(() => {
    if (changePassword && user.oldPassword && user.newPassword && user.repeatPassword) {
      if (user.newPassword !== user.repeatPassword) {
        setIsValid(false);
        setErrorsAndWarnings([], [{ message: "Password and repeat password does not match" }]);
      } else {
        setIsValid(true);
        setErrorsAndWarnings([], []);
      }
    } else {
      if (changePassword) {
        setErrorsAndWarnings([], [{ message: "Please enter all required fields" }]);
        setIsValid(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, changePassword]);
  const handleSubmit = async () => {

    setLoading(true);
    const sessionInfo = await authenticationService.getSessionInfo(user.email);

    if (!sessionInfo) return;

    try {
      const requestBody: User = {
        name: user.name,
        email: user.email,
        oldPassword: (user?.oldPassword !== null ? encrypt(user.oldPassword as string, sessionInfo.authKey) : undefined) as string | undefined,
        newPassword: (user?.newPassword !== null ? encrypt(user.newPassword as string, sessionInfo.authKey) : undefined) as string | undefined,
      }

      requestBody.timezone = tz;
      const data = await userService.updateUserProfile(requestBody);

      setSnackBar({
        message: changePassword ? "Password changed successfully" : "Profile updated successfully"
      });

      console.log(data);
      setTimeout(async () => {
        await fetchCurrentUserInfo();
      }, 1 * 1000);
      props.handleClose("refresh");
    } catch (error: any) {
      console.log(error);
      setErrorsAndWarnings(error.errors, error.warnings);
    } finally {
      setLoading(false);
    }
  };
  const onChangeHandler = (type: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(userActions.setUser({ ...user, [type]: e.target.value }))
  };
  const handleChangePassword = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChangePassword(e.target.checked);
    if (!e.target.checked) {
      dispatch(userActions.setUser({
        ...user,
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
      }))
    }
  };



  return (
    <DialogBox onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.state} fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        <Typography variant="h6" style={{ verticalAlign: "top" }}>
          {text.editProfile}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />
        {user?.authType?.includes("PASSWORD") ? (
          <Grid item>
            <Box mt={1}>
              <TextField
                variant="standard"
                label={text.userName}
                value={user.name}
                id="name"
                fullWidth
                required
                onChange={(e) => onChangeHandler("name", e)}
              ></TextField>
            </Box>
            <Box mt={1}>
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
                label={text.email}
                value={user.email}
                id="email"
                fullWidth
                required
                onChange={(e) => onChangeHandler("email", e)}
              ></TextField>
            </Box>
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePassword}
                    onChange={handleChangePassword}
                    name="changePassword"
                    color="primary"
                  />
                }
                label="Change Password"
              />
            </Box>
            {changePassword && (
              <>
                <Box mt={1}>
                  <TextField
                    variant="standard"
                    label={text.oldPassword}
                    value={user.oldPassword}
                    name="oldpassword"
                    type="password"
                    fullWidth
                    required
                    onChange={(e) => onChangeHandler("oldPassword", e)}
                  ></TextField>
                </Box>
                <Box mt={1}>
                  <TextField
                    variant="standard"
                    label={text.newPassword}
                    value={user.newPassword}
                    name="newpassowrd"
                    type="password"
                    onChange={(e) => onChangeHandler("newPassword", e)}
                    fullWidth
                    required
                  ></TextField>
                </Box>
                <Box mt={1}>
                  <TextField
                    variant="standard"
                    label={text.repeatPassword}
                    value={user.repeatPassword}
                    name="repeatpassword"
                    type="password"
                    onChange={(e) => onChangeHandler("repeatPassword", e)}
                    fullWidth
                    required
                  ></TextField>{" "}
                  <br /> <br />
                </Box>
              </>
            )}
            <TimeZone />
          </Grid>
        ) : (
          <Grid spacing={3} container direction={"row"}>
            <Grid xs={3} item>
              User Name
            </Grid>
            <Grid item xs={8}>
              :&nbsp;{user.name}
            </Grid>
            <Grid xs={3} item>
              Email
            </Grid>
            <Grid item xs={8}>
              :&nbsp;{user.email}
            </Grid>
            <Grid xs={3} item>
              Auth Type
            </Grid>
            <Grid item xs={8}>
              :&nbsp;{user?.authType?.join(",")}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button size="small" autoFocus onClick={handleSubmit} disabled={!isValid} color="primary">
          {text.updateProfile_btn}
        </Button>
      </DialogActions>
      <LoadingComponent />
    </DialogBox>
  );
};

export default EditProfile;


function TimeZone() {
  const dispatch = useAppDispatch();
  const tz = useAppSelector(selectTimeZone);

  const timeZones = React.useMemo(() => {
    return Object.entries(timezones).map(([tz, name]) => {
      return { label: name, value: tz, group: "All" };
    });
  }, []);

  const current = React.useMemo(() => {
    return { ...timeZones.find((zone) => zone.value === moment.tz.guess()), group: "System" }
  }, [timeZones]);

  return (
    <>
      <label style={{ fontSize: 10 }}>Timezone</label>
      <SearchSelector
        value={timeZones.find((zone) => zone.value === tz) || []}
        options={[current, ...timeZones]}
        onChange={(zone) => {
          if (zone) {
            dispatch(utilityActions.setTimeZone(zone.value as string));
            moment.tz.setDefault(zone?.value);
          }
        }}
        style={{ background: 'transparent', color: 'black', border: '1px solid #ccc' }}
      />
    </>

  )
}