/**
 * Copyright Highway9 Networks Inc.
 */

import { Hardware } from "~/types/Hardware";
import APIService from "./APIServices";
import { Product } from "~/types/Product";

const hardwareAPI = new APIService<Hardware>("hardware");
const productAPI = new APIService<Product>("products");

type HardwarePayload = {
  tenantId?: string;
  serviceInstanceId?: string;
  hardwareType: string;
  status?: string;
  id?: string;
}
type assignedHardware = {
  tenantId: string;
  serviceInstanceId: string;
  hardwareType: string;
  hardwareId: string;
  leaseStartDate: number;
  leaseExpiryDate: number;
  paidResource: boolean;
}



export const hardwareService = {
  getHardwares: (payload: HardwarePayload) => hardwareAPI.post('query', payload) as unknown as Hardware[],
  createHardware: hardwareAPI.create,
  deleteHardware: hardwareAPI.delete,
  editHardware: hardwareAPI.update,
  revokeHardware: (payload: HardwarePayload) => hardwareAPI.post('operations/revoke', payload) as unknown as Hardware[],
  assignHardware: (payload: { data : assignedHardware[]}) => hardwareAPI.post('operations/assign', payload),

  getProducts: productAPI.getAll,
  createProduct: productAPI.create,
  deleteProduct: productAPI.delete,
  editProduct: productAPI.update,
};



