import { useState } from 'react'

const useErrorsAndWarnings =()=>{
    const [errors,setErrors] = useState([] )
    const [warnings,setWarnings] = useState([])

    const closeAlert=(index,source)=>{

        if(source==="error"){
            const newErrorsArray = errors.filter((item,key)=> key !== index)
            setErrors(newErrorsArray)
        }else if(source==="warning"){
            const newWarningsArray = warnings.filter((item,key)=> key !== index)
            setWarnings(newWarningsArray)
        }

    }
    const closeAll =()=>{
        setErrors([])
        setWarnings([])
    }
    const setErrorsAndWarnings =(errorsData,warningsData)=>{
        setErrors(errorsData)
        setWarnings(warningsData)
    }
    return {errors,warnings, setErrorsAndWarnings,closeAll,closeAlert}
}

useErrorsAndWarnings.defaultProps ={
    errors :[],
    warnings : []
}

export default useErrorsAndWarnings