/**
 * Copyright Highway9 Networks Inc.
 */
import { Suspense, lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {
  TENANTS,
  ORDER_MANAGEMENT,
  VIRTUAL_MOBILITY_CLOUDS,
  HARDWARE_MANAGEMENT,
  SOFTWARE_RELEASE,
  SIM_MANAGEMENT,
  ECI_MANAGEMENT,
  USER_MANAGEMENT,
  AUDITLOGS,
 ADMINISTRATION
} from "../constants/routes";
import Layout from "./Layout";
import { CircularProgress } from "@mui/material";
import NotImplemented from "~/views/NotImplemented/NotImplemented";
import onAppStart from "./onAppStart";
import LoginExpiryAlert from "~/components/shared/LoginExpiryAlert";

const OperatorsPannel = lazy(() => import("../views/tenants/OperatorsPannel"));
const ServiceOrderManagePanel = lazy(() => import("../views/order/ServiceOrderManager"));
const VMC = lazy(() => import("../views/serviceInstance/vmc/VirtualMobilityCloud"));
const HardwareInventory = lazy(() => import("../views/hardwareInventory"));
const ReleasePanel = lazy(() => import("../views/Release/ReleasePanel"));
const SIMManagement = lazy(() => import("../views/SIMManagement"));
const ECIManagementPanel = lazy(() => import("../views/tenants/ECI/ECIManagementPanel"));
const UsersPanel = lazy(() => import("../views/users/UsersPanel"));
const AuditLog = lazy(() => import("../views/audit/AuditLog"));
const AdministrationPanel = lazy(() => import("../views/Administration/AdministrationPanel"));

type Props = {};

const AppRoutes = (_props: Props) => {
  useEffect(() => {
    onAppStart();
  }, []);
  return (
    <>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={TENANTS} component={() => <OperatorsPannel />} />
            <Route exact path={ORDER_MANAGEMENT} component={ServiceOrderManagePanel} />
            <Route exact path={VIRTUAL_MOBILITY_CLOUDS} component={VMC} />
            <Route exact path={HARDWARE_MANAGEMENT} component={HardwareInventory} />
            <Route exact path={SOFTWARE_RELEASE} component={ReleasePanel} />
            <Route exact path={SIM_MANAGEMENT} component={SIMManagement} />
            <Route exact path={ECI_MANAGEMENT} component={ECIManagementPanel} />
            <Route exact path={USER_MANAGEMENT} component={UsersPanel} />
            <Route exact path={AUDITLOGS} component={AuditLog} />
            <Route exact path={ADMINISTRATION} component={AdministrationPanel} />
            <Route component={NotImplemented} />
          </Switch>
        </Suspense>
        <LoginExpiryAlert />
      </Layout>
    </>
  );
};

export default AppRoutes;
function Loading() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
