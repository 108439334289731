import React from "react";
import TopBar from "./TopBar";
import { Grid } from "@h9/ui-lib";
import SideDrawer, { DRAWER_WIDTH } from "./SideDrawer";
import { Images } from "~/components/GraphicalResources";
import { Drawer, List } from "@mui/material";

type Props = {
  children: React.ReactNode;
  hideNavBar?: boolean;
};

const Layout = (props: Props) => {
  const styles = {
    wrapper: {
      background: "#226A58",
      height: "100vh",
      flexWrap: "nowrap",
      width: "100%",
      justifyContent: "flex-end",
    },
    main: {
      flexGrow: 1,
      maxWidth: "100%",
      overflow: "auto",
      height: "100%",
      background: "#F7F7F6",
      padding: "16px",
    },
    container: {
      overflow: "auto",
      flexGrow: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
      paddingTop: "16px",
      paddingRight: `${props.hideNavBar}`,
    },
    logo: {
      padding: "22px 0 24px",
    },
    listContainer: {
      padding: "8px 16px",
      "& a": {
        color: "#0000008a",
      },
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      textAlign: "center",
      "& .MuiDrawer-paper": {
        background: "#226A58",
        width: DRAWER_WIDTH,
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: 0,
      },
    },
  };

  return (
    <Grid container sx={styles.wrapper}>
      {!props.hideNavBar ? (
        <SideDrawer />
      ) : (
        <Drawer variant="permanent" sx={styles.drawer}>
          <Grid height="100%" container flexDirection="column" justifyContent="space-between">
            <List sx={styles.listContainer}>
              <Grid sx={styles.logo}>
                <img src={Images.highway9Logo} alt="h9-logo" />
              </Grid>
            </List>
          </Grid>
        </Drawer>
      )}
      <Grid container item sx={styles.container}>
        <TopBar />
        <Grid item component="main" sx={styles.main}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
