/**
 * Copyright Highway9 Networks Inc.
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { RootState } from "..";
import { User } from "~/types/user";

type initState = {
    time : {
        timezone : string;
    }
    user : User | null;
};

const initialState : initState = {
    time : {
        timezone : moment.tz.guess()
    },
    user : null
};

const utilitySlice = createSlice({
    name : "utility",
    initialState,
    reducers : {
        setTimeZone : (state, action : PayloadAction<string>) => {
            state.time.timezone = action.payload;
        },
        setUser : (state, action : PayloadAction<User>) => {
            state.user = action.payload;
        }
    }
});

export const utilityActions = utilitySlice.actions;
export default utilitySlice.reducer;
export const selectTimeZone = (state : RootState) => state.utility.time.timezone;
