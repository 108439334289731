import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Hardware } from "~/types/Hardware";
import { hardwareService } from "~/services";

export interface HardwareX extends Hardware {
    tenantId?: string;
    serviceInstanceId?: string;
    hardwareType: string;
    status?: string;
    tenantName:string;
    serviceInstanceName:string;
}


type initState = {
    downloadTemplate: {
        open: boolean;
        isRunning: boolean;
    };
    import: {
        open: boolean;
        data: any[];
        filteredData: any[];
        dataCount: number;
        filteredDataCount: number;
        preserveSign: boolean;
    };
    loading: boolean;
    current: Hardware | null;
    data: Array<Hardware>;
    open: boolean;
}

const initialState: initState = {
    downloadTemplate: {
        open: false,
        isRunning: false
    },
    import: {
        open: false,
        data: [],
        filteredData: [],
        dataCount: 0,
        filteredDataCount: 0,
        preserveSign: true,
    },
    loading: false,
    current: null,
    data: [],
    open: false,
}

export const fetchHardwares = createAsyncThunk(`hardware/fetchHardware`, async () => {
    try {
        const data = await hardwareService.getHardwares({
            tenantId: "",
            serviceInstanceId: "",
            hardwareType: "",
            status: ""
        })
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
});

const hardwareSlice = createSlice({
    name: "hardware",
    initialState,
    reducers: {
        setOpenDownloadTemplate: (state, action: PayloadAction<boolean>) => {
            state.downloadTemplate.open = action.payload;
        },
        setDownloadTemplateIsRunning: (state, action: PayloadAction<boolean>) => {
            state.downloadTemplate.isRunning = action.payload;
        },
        setOpenImport: (state, action: PayloadAction<boolean>) => {
            state.import.open = action.payload;
        },
        setImportData: (state, action: PayloadAction<any[]>) => {
            state.import.data = action.payload;
            state.import.dataCount = action.payload?.length;
        },

        setPreserveSign: (state, action: PayloadAction<boolean>) => {
            state.import.preserveSign = action.payload;
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setCurrent: (state, action: PayloadAction<Hardware | null>) => {
            state.current = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHardwares.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchHardwares.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchHardwares.rejected, (state) => {
            state.loading = false;
        });
    }
})
export const hardwareActions = hardwareSlice.actions;
export default hardwareSlice.reducer;
export const HardwareState = (state: RootState) => state.hardware.current
export const HardwareData = (state: RootState) => state.hardware.data
export const HardwareOpen = (state: RootState) => state.hardware.open


export const hardwareTemplateOpen = (state: RootState) => state.hardware.downloadTemplate.open
export const hardwareTemplateRunnig = (state: RootState) => state.hardware.downloadTemplate.isRunning

export const hardwareLoading = (state: RootState) => state.hardware.loading;

export const hardwareImportOpen = (state: RootState) => state.hardware.import.open;
export const hardwareImportData = (state: RootState) => state.hardware.import.data;
export const hardwareImportDataCount = (state: RootState) => state.hardware.import.dataCount;
export const hardwarePreserveSign = (state: RootState) => state.hardware.import.preserveSign;

export const hardwareImportFilteredData = (state: RootState) => state.hardware.import.filteredData;
export const hardwareImportFilteredDataCount = (state: RootState) => state.hardware.import.filteredDataCount;