import { ServiceExtension } from "~/types/ServiceExtension"
import APIService from "./APIServices"


const serviceExtensionAPI = new APIService<ServiceExtension>("serviceExtension")

const serviceExtensionService = {
    getAll: serviceExtensionAPI.getAll
}

export default serviceExtensionService
