// @Copyright Highway9 Networks Inc. 2023-2024
import React from 'react'
import './App.css'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from './helpers'
import { LoginPage } from './navigation/LoginPage'
import AppRoutes from './navigation/AppRoutes'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { ResetPasswordPage } from './navigation/ResetPasswordPage'
import ErrorBoundary from './components/ErrorBoundary'
import SnackbarX from './components/shared/SnackbarX'
import { theme } from "./styles/theme"
import { AuthRouter } from "./navigation/AuthRouter"
import TNCPanel from './views/eula/TNCPanel'

const App = () => {
    return (
        <ErrorBoundary>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/resetPassword" component={ResetPasswordPage} />
                            <Route exact path="/eula" component={TNCPanel} />
                            <AuthRouter path="/" component={AppRoutes} />
                        </Switch>
                    </Router>
                    <SnackbarX />
                </ThemeProvider>
            </StyledEngineProvider >
        </ErrorBoundary>
    )
}
export default App
