import {JSEncrypt} from "jsencrypt";

export function groupBy<T>(array: T[], property: string) {
    const hash: {
      [key: string]: T[];
    } = {};
    // split the properties into an array by '.'
    const props = property.split(".");
    // loop through the array
    for (let i = 0; i < array.length; i++) {
      // get the current item
      const item = array[i];
      // get the current property value
      const key = props.reduce(function (acc, prop) {
        // if acc is object, get the property value if not, return acc
        return acc && acc[prop];
      }, item as any);
      // if the key is not in the hash, add it
      if (!hash[key]) hash[key] = [];
      // add the current item to the hash
      hash[key].push(item);
    }
    return hash;
  }
  



  export function encrypt(text: string, publicKeyPem: string): string | null {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKeyPem);

    const encryptedString = encryptor.encrypt(text);
    if (!encryptedString) {
        console.error("Error encrypting string");
        return null;
    }

    return encryptedString; // Base64 encode the encrypted string
}

/**
 *
 * @param role - The role string e.g. READONLY_TENANT
 * @returns The formatted role string e.g. Readonly Tenant
 */
export function roleStringFormatter(role: string) {
  let result = role.replaceAll("_", " ").toLowerCase();
  result = result[0].toUpperCase() + result.slice(1);
  return result;
}
