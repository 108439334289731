/**
 * Copyright Highway9 Networks Inc.
 */
 import * as React from 'react';
 import Button from '@mui/material/Button';

 import DialogContentText from '@mui/material/DialogContentText';

import { DialogBox,DialogTitle,DialogContent,DialogActions } from '~/styles/Dialog';
 
 type Props = {
     open: boolean;
     setOpen: (open: boolean) => void;
     title: string | JSX.Element;
     message: string | JSX.Element;
     onCancel?: () => void;
     onConfirm?: () => void | Promise<void>;
     confirmText?: string;
     cancelText?: string;
     confirmColor?: 'error' | 'success' | 'warning' | 'info' | 'primary' | 'secondary';
     cancelButton?: boolean;
     confirmButton?: boolean;
     children?: JSX.Element;
     backdropClick?: boolean;
 }
 
 export default function AlertDialog(props: Props) {
     const { open, setOpen, title, message, onCancel, onConfirm, confirmText = "OK", cancelText = "Cancel", cancelButton = true, confirmButton = true, confirmColor = 'primary', children } = props;
     const handleClose = () => {
         setOpen(false);
     };
 
     return (
         <div>
             <DialogBox
                 data-testid="alert-dialog-container"
                 open={open}
                 onClose={handleClose}
                 aria-labelledby="alert-dialog-title"
                 aria-describedby="alert-dialog-description"
             >
                 <DialogTitle id="alert-dialog-title">
                     {title}
                 </DialogTitle>
                 <DialogContent>
                     <DialogContentText id="alert-dialog-description">
                         {message}
                     </DialogContentText>
                     {children}
                 </DialogContent>
                 <DialogActions>
                     {cancelButton &&
                         <Button size='small' data-testid="alert-dialog-cancel-btn" onClick={() => {
                             try {
                                 if (onCancel) onCancel();
                                 handleClose();
                             } catch (error) {
                                 console.log(error);
                                 throw error;
                             }  
                         }}>{cancelText}</Button>
                     }
                     {confirmButton &&
                         <Button size='small' data-testid="alert-dialog-confirm-btn" variant='contained' color={confirmColor} onClick={async () => {
                             try {
                                 if (onConfirm) await onConfirm();
                                 handleClose();
                             } catch (error) {
                                 console.log(error)
                                 throw error;
                             }
                         }} autoFocus>{confirmText}</Button>}
                 </DialogActions>
             </DialogBox>
         </div>
     );
 }