/**
 * Copyright Highway9 Networks Inc.
 */
import withStyles from "@mui/styles/withStyles";
import { Badge } from "@mui/material";
import { styled } from "@mui/styles";

const styles = (theme: { spacing: (arg0: number) => any }) => ({
  margin: {
    margin: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: (props: { badgeContent: any }) => getIconColor(getIconText(props.badgeContent)),
    color: "white",
  },
});

function getIconText(text: string | null) {
  text = text?.trim() ?? "";
  let result = "NA";
  if (text == null) {
    return result;
  }
  if (text.length === 0) {
    result = "";
  } else if (text.length === 1) {
    result = text.charAt(0);
  } else if (text.length >= 2) {
    const words = text.split(" ");
    if (words.length >= 2) {
      result = words[0].charAt(0) + words[1].charAt(0);
    } else {
      result = text.charAt(0) + text.charAt(1);
    }
  }
  return result.toUpperCase();
}

function getIconColor(text: string) {
  const colors = [
    "#1E00C2",
    "#CF0067",
    "#A2CC29",
    "#54FF00",
    "#FF7002",
    "#B9B9B9",
    "#E2F000",
    "#EBB0DB",
    "#E41831",
    "#440078",
    "#FDFF81",
    "#EA335B",
    "#DC9025",
    "#004C67",
    "#FF0100",
    "#646464",
    "#000000",
    "#0E480F",
    "#8E0F8D",
    "#3E8BD9",
    "#00A000",
    "#A2DECC",
    "#FE90D9",
    "#0D004F",
    "#224E9C",
    "#471700",
  ];
  const code = ((text.charCodeAt(0) - 65) * 10 + (text.charCodeAt(1) - 65)) % 26;
  return colors[code % colors.length];
}

export function SimpleBadge(props: { badgeContent?: any; classes?: any }) {
  const { classes } = props;

  const iconText = getIconText(props.badgeContent);

  return <Badge classes={{ badge: classes.customBadge }} className={classes.margin} badgeContent={iconText}></Badge>;
}

export const StyledBadge = withStyles(styles)(SimpleBadge);

export const StyledBadge1 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 14,
    top: 18,
    border: `2px solid #026950`,
    padding: "0 4px",
    backgroundColor: "rgb(232,238,237)",
    height: 20,
    width: 15,
    color: "#026950",
  },
}));
